import Cookies from 'js-cookie'

const TokenKey = 'sw-token';
const swName = 'sw-name';

export function getSrtk() {
  return Cookies.get('srtk')
}

export function setSrtk(item) {
  return Cookies.set('srtk', item,{ expires: 10 })
}
export function removeSrtk() {
    return Cookies.remove('srtk')
}

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token,{ expires: 60 })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function setSwName(name) {
  return Cookies.set(swName, name, {expires: 60})
}

export function getSwName() {
  return Cookies.get(swName)
}
