let dev = process.env.NODE_ENV !== 'production';
let host = dev ? 'http://localhost:20215' : 'https://svip.fanboshi.net';
const port = '20211';
const Base_url = host;

export {
    Base_url,
    port,
    host
}
