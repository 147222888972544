import router from './router/index';
import {Message} from "element-ui";
import { getToken, removeToken,setToken,setMember,removeMemberId,getMemberId } from './utils/auth'
import {  checkToken } from './api/login'

const whiteList = ['/login', '/404', '/403','/login'];


router.beforeEach(async (to, from, next) => {
    // let _id = getMemberId();
    let _token = getToken();
    if (_token) {
        // checkToken(_token,_id).then(res=>{
        //     if (res.code==200) {
        //           if (res.user!=null){
        //             setMember(res.user)
        //           }
        //           if (res.token!=null){
        //             setToken(res.token)
        //           }
        //         if (whiteList.indexOf(to.path) !== -1) {
        //             next('/appointments/appointment')
        //         } else {
        //             next()
        //         }
        //     } else {
        //         Message('登录超时,请重新登录！')
        //       removeMemberId();
        //         next('/login')
        //     }
        // }).catch(res=>{
        //     Message('登录超时,请重新登录！')
        //   removeMemberId();
        //     next('/login')
        // });
              if (whiteList.indexOf(to.path) !== -1) {
                  next('/cashiers/cashier')
              } else {
                  next()
              }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }
})

